<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1>Woo TV 支持</h1>
    <h3 style="color: #D4C49C; padding-bottom: 100px;" >Woo TV Support</h3>
    <div class="card-container">
      <Card icon="what.png" title="这是什么" description="WooTV是一款视频播放器" link="https://wootv.dmxx.ltd/" linkText="我要使用"/>
      <Card icon="format.png" title="兼容万物" description="可以在线播放m3u8，mp4等视频资源" link="https://wootv.dmxx.ltd/" linkText="什么都能看"/>
      <Card icon="operate.png" title="适合宝宝体质的操作界面" description="WooTV的ui使用swiftui框架制作，流畅的动画，符合人体工学的操作让你用得更舒服" link="https://wootv.dmxx.ltd/" linkText="即刻尝试"/>
      <Card icon="save.png" title="收藏视频" description="WooTV内置优雅的存储功能，你可以将你找到的在线资源链接存储在WooTV，以便于下次直接观看" link="https://wootv.dmxx.ltd/" linkText="快"/>
      <Card icon="sofa.png" title="SharePlay" description="WooTV加入了apple令人震惊的shareplay功能，以最低的延迟让你与你的朋友共享一部影片" link="https://wootv.dmxx.ltd/" linkText="awesome"/>
      <Card icon="attention.png" title="注意了" description="部分在线资源需要WiFi网络环境才能播放，如果你遇到一直加载的问题不妨切换网络环境的尝试" link="https://wootv.dmxx.ltd/" linkText="WiFi让你的连接更稳定"/>
      <Card icon="happy.png" title="感谢使用" description="当你使用这款app有任何建议或者想法欢迎与我沟通" link="https://wootv.dmxx.ltd/" linkText="hello开发者"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
.logoimg {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: #D4C49C;
  padding-top: 100px;
}
</style>